import Head from 'next/head';

const Versioning = () => {
  return (
    <Head>
      <meta name="version" content="1.1.2.32891" />
      <meta name="build-date" content="2024-10-14T22:43:53Z" />
    </Head>
  );
};

export default Versioning;
